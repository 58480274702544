import './App.css';
import { Routes, Route } from "react-router-dom";
import IframeContainer from './components/IframeContainer';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <IframeContainer
              url="https://ledger-ui.baanx.co.uk/iframeV2/view/onboarding/login"
              title='CL Account'
            />
          }
        />
        <Route
          path="/login"
          element={
            <IframeContainer
              url="https://ledger-ui.baanx.co.uk/iframeV2/view/onboarding/login"
              title='Login'
            />
          }
        />
         <Route
          path="/signup"
          element={
            <IframeContainer
              url="https://ledger-ui.baanx.co.uk/iframeV2/view/onboarding/signup"
              title='Signup'
            />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
