import PropTypes from 'prop-types';
import React from 'react';

const IframeContainer = ({ url, title, style }) => (
  <iframe
    style={style || {
      height: '100%',
      width: '100%',
      borderWidth: 0,
      display: 'block',
      position: 'fixed',
    }}
    allow='autoplay; camera; microphone; clipboard-read; clipboard-write; same-origin'
    title={title}
    src={url}
  />
);
export default IframeContainer;

IframeContainer.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
};

IframeContainer.defaultProps = {
  className: '',
  title: '',
  style: null,
};
